<template>
  <div v-if="authUserIsAdmin">
    <LoadingMessage v-if="isLoading" :message="'Fetching coaches...'"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

    <form
      @submit.prevent
      v-if="!isLoading && !error"
      class="d-flex align-items-center bg-dark p-2 rounded"
    >
      <div class="form-floating-cta-container">
        <div class="form-floating-cta">
          <fa-icon
            @click="resetFilter"
            v-if="keyword.length"
            :icon="['fas', 'times-circle']"
            class="text-white"
            role="button"
          ></fa-icon>
        </div>
        <input
          v-model.trim="keyword"
          ref="keyword"
          type="text"
          class="form-control form-control-dark"
          placeholder="Search user..."
        >
      </div>
      <IconButton
        @click="fetchUsers"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading && !error" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
              <th scope="col" class="align-baseline">Email</th>
              <th scope="col" class="align-baseline">No of clients</th>
              <th scope="col" class="align-baseline">Joined at</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody class="small">
            <tr v-if="!pagedUsers.length">
              <td colspan="6">No coaches found</td>
            </tr>
            <CoachListItem
              @update="updateUser"
              v-for="(user, userIndex) in pagedUsers"
              :key="`key-${user.userId}`"
              :user="user"
              :index="userIndex + 1"
            ></CoachListItem>
          </tbody>
        </table>
      </div>
    </div>

    <nav v-if="!isLoading && !error && totalPage > 1" class="d-flex justify-content-center">
      <ul class="pagination mt-5 mb-0">
        <li
          @click="currentPage = index"
          v-for="index in totalPage"
          :key="`key-${index}`"
          :class="{ 'disabled': index === currentPage }"
          class="page-item"
          role="button"
        >
          <a class="page-link">
            <span :class="{ 'is-busy': index === currentPage }">{{ index }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'CoachList',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    CoachListItem: () => import('./CoachListItem.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
    filteredUsers() {
      let arr = [];
      const { keyword, users } = this;

      const q = keyword.trim().toLowerCase();
      if (q === '') {
        arr = users;
      } else {
        arr = users.filter((user) => {
          const { firstName, lastName, email } = user;
          return firstName.toLowerCase().indexOf(q) >= 0
            || lastName.toLowerCase().indexOf(q) >= 0
            || email.toLowerCase().indexOf(q) >= 0;
        });
      }

      return arr;
    },
    pagedUsers() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredUsers.slice(startIndex, endIndex);
    },
    totalPage() {
      const { filteredUsers, perPage } = this;
      if (filteredUsers.length) {
        return Math.ceil(filteredUsers.length / perPage);
      }
      return 0;
    },
  },
  watch: {
    keyword() {
      this.currentPage = 1;
    },
  },
  methods: {
    async fetchUsers() {
      this.isLoading = true;
      this.error = null;
      this.keyword = '';
      this.currentPage = 1;
      try {
        const res = await httpGet('/admin/users', { type: 'coach' });
        this.users = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
    updateUser(user) {
      const index = this.users.findIndex((u) => u.userId === user.userId);
      if (index > -1) this.users.splice(index, 1, user);
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      users: [],
      keyword: '',
      perPage: 25,
      currentPage: 1,
    };
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 35%;
  }
  &:nth-child(2) {
    width: 35%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 10%;
  }
  &:nth-child(5) {
    width: 10%;
  }
}
</style>
